import React from 'react';
import '../styles/App.css';
import Main from './Main';

const Home = () => {
  return (
    <Main />
  );
};

export default Home;